.epow-text-link {
  text-decoration: underline;
  color: $dark;

  &:hover {
    color: $secondary;
  }

  &.no-underline {
    text-decoration: none;
  }

  &.header-indicator-link {
    background-color: $white;
    line-height: 1.8;
    font-size: 0.8rem;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
    border-radius: $border-radius-sm;
    border: 1px solid $gray ;
    display: flex;
    align-items: center;

    &:hover {
      color: $white;
      background-color: $secondary;
      border-color: $secondary;
    }
  }
}