.epow-contact-card.card {
  min-width: 300px;
  max-width: 400px;
  width: 100%;

  .line-info-contact {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span, a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }
  }

}