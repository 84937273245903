.Toastify__toast {
  width: 325px;
  min-height: 65px;
  color: white;
  font-weight: bold;
  border-radius: 7px;
}

.Toastify__toast--warning{
  background-color: $warning;
}

.Toastify__toast-icon {
  align-self: start;
}

.Toastify__toast--error{
  background-color: $danger;
}

.Toastify__toast--success{
  background-color: $success;
}

.Toastify__toast--info{
  background-color: $primary;
}

.Toastify__toast--default{
  background-color: $danger;
}

.pow-info-toast {
  line-height: 25px;
  box-shadow: 0 12px 16px -4px #0074AC59;
}
