.epow-custom-table.booking {
  display: flex;
  flex-direction: column;

  .epow-custom-table-header-row.booking {
    display: flex;

    .epow-custom-table-header.booking {
      display: flex;
      flex: 1 0;
      padding: 0.5rem;

      >span {
        font-weight: bold;
        margin-right: 0.5rem;
      }
    }
  }

  .epow-custom-table-row.booking {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    margin-bottom: 0.5rem;

    .epow-custom-table-row-main {
      display: flex;
      flex: 1 0;
      align-items: center;
      border: 1px solid $light;
      border-radius: 5px;
      background-color: $white;

      .col {
        padding: 1rem 0.5rem;
      }
    }

    .epow-custom-table-row-details {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: $white;
      border-radius: 5px;
      padding: 0.5rem;
      margin-bottom: 0.25rem;
    }
  }

}
