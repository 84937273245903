.epow-staffing-table {
  display: flex;
  flex-direction: column;

  .epow-staffing-table-header-row {
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 1rem;

    .epow-staffing-table-header {
      display: flex;
      flex: 1 0;

      >span {
        font-weight: bold;
        margin-right: 0.5rem;
      }

      &:last-child {
        >span {
          margin-left: auto;
          margin-right: 1rem;
        }
      }
    }
  }

  .epow-staffing-table-row {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    background-color: $white;
    margin-bottom: 0.1rem;
    padding: 0.75rem;

    .epow-staffing-table-row-title {
      margin-bottom: 0.25rem;
      font-weight: bold;
      font-size: 14px;
    }

    .epow-staffing-table-row-details {
      display: flex;
      align-items: center;
      background-color: $white;
      border-radius: 5px;
      padding: 0.5rem;
      margin-bottom: 0.25rem;
    }
  }

}
