
.epow-icon {
  display: inline-block;
  height: $icon-size;
  width: $icon-size;
  vertical-align: middle;
  margin: auto;
  box-sizing: content-box;
  flex-shrink: 0;

  svg, .svg-inline--fa {
    display: block;
    width: 100%;
    height: 100%;

    * {
      fill: currentColor;
    }
  }

  &.rounded {
    position: relative;
    padding: 0.5rem;

    .round {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      * {
        fill: currentColor;
      }
    }
    svg:not(.round) {
      position: relative;

      * {
        fill: $body-bg;
      }
    }
  }
}

// Sizes
@each $size, $value in $icon-sizes {
  .icon-size--#{$size} {
    height: $value;
    width: $value;
  }
}

@each $color, $value in $theme-colors {
  .icon-color--#{$color} {
    color: $value;
  }
}

.gradient-blue-green {
   background: $gradient-primary-secondary
}
