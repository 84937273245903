.btn-group {
  margin: 0 1rem;

  .epow-button.btn {
    border-radius: 0;
    margin: 0;
    border-color: $gray-color-text;
    font-weight: 600;
    padding-right: 30px;
    padding-left: 30px;

    &.loading {
      padding-left: 10px;
    }

    &.btn-tertiary {
      color: $white;
      border-color: $secondary;
    }

    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &:not(:first-child) {
      margin-left: -1px;
    }

  }
}
