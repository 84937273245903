.epow-button.btn {
  border-radius: 6px;

  &.main-gradient-button {
    background: $gradient-primary-secondary;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    line-height: 2;
  }

  &.btn-primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;
    font-weight: 600;
  }

  &.btn-secondary {
    background-color: $white;
    border-color: $dark;
    color: $dark;
    font-weight: 600;
  }

  &.btn-tertiary {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;
    font-weight: 600;
  }

  &.btn-transparent {
    background-color: transparent;
  }

  &.btn-black {
    border: 1px solid $light;
    color: $light;
  }

  &.btn-xs {
    line-height: 1.5;
    font-size: 0.8rem;
  }

  &.btn-md {
    line-height: 1.8;
    font-size: 0.8rem;

    &.extended {
      padding-right: 30px;
      padding-left: 30px;
    }

    &.loading {
      padding-left: 10px;
    }
  }
}
