.epow-purchase-card {
  display: flex;
  flex-direction: column;

  .epow-purchase-card-header-row {
    display: flex;

    >span {
      font-weight: bold;
      margin-right: 0.5rem;
    }

    .epow-purchase-card-header {
      display: flex;
      flex: 1 0;
      margin-top: 1rem;

      >span {
        font-weight: bold;
        margin-right: 0.5rem;
      }

      &:last-child {
        >span {
          margin-left: auto;
          margin-right: 1rem;
        }
      }
    }
  }

}