.epow-tab-pane {
  padding: 10px 20px;
  text-align: center;
  font-size: 0.9rem;

  &.active {
    border-bottom: $secondary 3px solid;
    color: $secondary;
    font-weight: 600;
  }
}