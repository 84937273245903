.epow-nav {
  position: sticky;
  padding: 24px 24px 8px 24px;
  height: 100vh;

  width: 100%;

  @include media-breakpoint-up(lg) {
    width: $side-menu-width;
    padding: 15px 0 0 0;
  }

  top: 0;
  background-color: $dark;
  border: 1px solid $dark;
  overflow-y: auto;

  & .separator {
    border-top: 1px solid #434E55;
  }
}

.epow-menu-item {
  margin: 0 15px;
  border-radius: 5px;
  padding: 10px;
  font-size: $font-size-base;
  color: $gray;

  &.sub-item {
    padding: 6px;
    .epow-label {
      font-size: 0.9rem;
      color: rgba(229, 229, 229, 0.8);
    }
  }

  @include media-breakpoint-down(lg) {
    width: 70%;
    margin: 0;
  }
}

.epow-close {
  left: 0;
}

.epow-menu-item.epow-text-link.active, .epow-menu-item.epow-text-link:hover {
  background: $gradient-primary-secondary;
  span, .epow-label {
    color: $white;
  }
}

.epow-side-menu-panel {

  @include media-breakpoint-down(lg) {
    width: 100%;
  }

  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  overflow: auto;
}
