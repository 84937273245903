.epow-tooltip {
  .tooltip-inner {
    font-size: 1rem;
    color: $dark;
    text-align: left;
    background-color: $light-blue;
    border: 1px solid $secondary;
    min-width: 200px;
    max-width: 400px;
  }
}
