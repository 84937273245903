// EPOW VARIABLES
// --------------------------------------------------


// DEFAULT VARIABLES
// --------------------------------------------------

// Fonts body
$font-family-base: "Poppins", sans-serif;

$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;

$font-size-base: 1rem;
$font-size-md: 1rem;
$font-size-sm: 1rem;
$font-size-lg: 1.6rem;
$font-size-xs: 0.8rem;

// Fonts headings
$headings-font-family: $font-family-base;
$headings-font-weight: $font-weight-bold;

// Colors

// Main colors
$primary:         #44B89B;
$primary-light:   rgba(68, 184, 155, 0.1);
$primary-border:   rgba(68, 184, 155, 0.4);
$secondary:       #3477C9;
$secondary-light: rgba(52, 119, 201, 0.1);
$secondary-border: rgba(52, 119, 201, 0.4);
$dark:            #081520;


// Complementary colors
$dark-blue:       #293E58;
$dark-green:      #0A3344;
$blue:            #1F158C;
$light-blue:      #E3F6FC;
$dark-red:        #7B0C41;
// --------------------------------------------------
$gray:            #E5E5E5;
$light:           #F7F7F7;
$white:           #FFFFFF;
$cyan:            #C6FEFF;

// Functionnal colors
$alert: #D92D20;
$success: #198754;
$green: #12B76A;
$warning-badge: #D68101;
$warning: #F79009;
$danger:  #D92D20;
$dark-gray: #222F3A;
$gray-color-text: #8A9095;
$gradient-primary-secondary: linear-gradient(90deg, #3477C9 0%, #44B89B 100%);
$bg-light: #F9F9FC;
$blue-gray: #434E55;

$theme-colors: (
  "primary":       $primary,
  "secondary":     $secondary,
  "tertiary":      $dark-blue,
  "info":          $blue,
  "light":         $light,
  "dark":          $dark,
  "success" :      $success,
  "warning" :      $warning,
  "danger":        $danger,
  "gray":          $gray-color-text,
  "dark-gray": $dark-gray,
  "dark-red": $dark-red,
  "light-blue": $light-blue,
  "bg-light": $bg-light,
  "blue-gray": $blue-gray,
  "green": $green,
);


$badge-theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary":  $dark-blue,
  "info": $blue,
  "light": $light,
  "success" : $success,
  "warning" : $warning-badge,
  "danger": $danger,
  "alert": $alert,
  "dark": $dark,
  "gray": $gray-color-text,
  "dark-gray": $dark-gray,
  "green": $green,
);

$text-muted: #7A808F;


$theme-colors-functionnal: (
        "alert":            $alert
);

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

// Global ratio
$global-percent: 62.5%;
$global-font-size: 12px;

// Figure Ratios
$ratios: (
        '2-3': 2/3,
        '3-4': 3/4,
        '1-1': 1/1,
        '4-3': 4/3,
        '3-2': 3/2,
        '16-9': 16/9
);

// Figure Occupation
$occupations: (
        "50": 0.5,
        "60": 0.6,
        "70": 0.7,
        "80": 0.8,
        "90": 0.9,
);

// Border
$border: 0.1rem;
$border-lg: 0.2rem;

// Border-radius
$border-radius-none:          0;
$border-radius-lg:            1.6rem;
$border-radius-md:            1rem;
$border-radius-sm:            0.4rem;
$border-radius-pill:          10rem;

$border-radius:               $border-radius-none;

// Box shadow
$box-shadow-lg: 0 1rem 2rem -1rem rgba($dark, 0.2);
$box-shadow-card: 0 0.5rem 1rem -0.5rem rgba($dark, .2);

$card-ticket-shadow: 0 0.1rem 0.2rem -0.1rem rgba($dark, 0.2);
$card-ticket-shadow-lg: 0 0.2rem 0.4rem -0.2rem rgba($dark, 0.3);
$card-ticket-shadow-inset: inset 0 -0.2rem 0.4rem rgba($dark, 0.2);

$card-ticket-around-shadow: 0 0 0.4rem rgba($dark, 0.2);
$card-ticket-around-shadow-lg: 0 0 0.8rem rgba($dark, 0.3);
$card-ticket-around-shadow-inset: inset 0 0 0.4rem rgba($dark, 0.2);

// Alpha
$alpha-base: 0.5;
$alpha-base-hover: 0.25;
$alpha-overlay: 0.8;

// Icon
$icon-size: 1em;
$icon-sizes: (
        "0": 9px,
        "1": 14px,
        "2": 20px,
        "3": 28px,
        "4": 32px,
        "5": 48px,
        "6": 54px
);

// Layout
$side-menu-width: 250px;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: 40px,
) !default;


// GRID
$grid-columns: 24;
$grid-gutter-width: 10px;
$grid-row-columns: 12 !default;

$gutters: $spacers !default;

// IMPORT BOOTSTRAP VARIABLES
// --------------------------------------------------

@import "~bootstrap/scss/variables";
