.epow-staffing-confirmed-table {
  // this is pretty much the same table
  @extend .epow-staffing-pending-table;

  .epow-custom-table-header-row.staffing {
    .epow-custom-table-header.staffing {
      &:last-child {
        >span {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }

  .epow-staffing-pending-table-row-details {
    @extend .epow-staffing-pending-table-row-details;
  }
}