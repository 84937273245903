$color-black: #000000;
$color-red: #ff0000;
$color-blue: #0000ff;
$color-green: #00ff00;
$color-yellow: #e2e630;

.color-picker-wrapper {
  position: relative;

  .color-picker-select {
    padding: 2px;
  }

  .color-picker {
    background-color: $white;
    position: fixed;
    border: solid $gray-color-text 1px;
    border-radius: 5px;
    padding: 10px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    .color-picker-option {
      .square {
        height: 15px;
        width: 15px;
        border: 1px solid black;
      }
    }
  }
}