.epow-select {
  font-size: $font-size-base;
  border-radius: 4px !important;
  min-width: 170px;

  &.epow-select-error {
    border: 1px solid $danger;
  }
  &.rmsc .gray {
    font-style: italic;
    color: #6c757d;
  }
}