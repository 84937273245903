.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &.green {
    background-color: $success;
  }

  &.red {
    background-color: $danger;
  }

  &.orange {
    background-color: $warning-badge;
  }

  &.md {
    width: 20px;
    height: 20px;
  }

  &.lg {
    width: 30px;
    height: 30px;
  }
}
