// Poppins (300, 300i, 400, 400i, 700)
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

// TYPE
@import "~bootstrap/scss/type";

body {
  font-weight: $font-weight-normal;
  font-size: 12px;
}

// strong
strong {
  font-weight: $font-weight-bold;
}

// .hn
h1, .h1, h2, .h2, h3, .h3 {
  font-weight: $headings-font-weight;
}
h4, .h4, h5, .h5, h6, .h6 {
  font-weight: $font-weight-bold;
}
h1, .h1 {
  font-size: $h1-font-size;
}
h2, .h2 {
  font-size: $h2-font-size;
}
h3, .h3 {
  font-size: $h3-font-size;
}
h4, .h4 {
  font-size: $h4-font-size;
}
h5, .h5 {
  font-size: $h5-font-size;
}
h6, .h6 {
  font-size: $h6-font-size;
}
