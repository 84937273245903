.react-calendar {
  background: linear-gradient(180deg, #E3F6FC 0%, rgba(227, 246, 252, 0) 100%);
  width: 300px;
  border-radius: 6px;
  padding: 14px;
  font-size: 12px;

  & .react-calendar__month-view__days .react-calendar__month-view__days__day {
    width: 31px;
    height: 31px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  & .react-calendar__tile--now {
    text-decoration: underline;
  }


  & .react-calendar__tile--active, abbr {
    position: relative;
  }

  & .react-calendar__tile--active:before {
    content: " ";
    width: 30px;
    height: 30px;
    background-color: white;
    position: absolute;
    border: 1px solid #081520;
    border-radius: 50%;
    bottom: 1px;
    left: 5px;
  }

  & .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    & span {
      font-weight: bold;
    }

    & .react-calendar__navigation__arrow {
       font-size: 26px;

      &.react-calendar__navigation__prev-button, &.react-calendar__navigation__next-button {
        padding: 0 12px;
      }
    }
  }

  & .react-calendar__month-view__days__day--neighboringMonth {
    color: $gray-color-text
  }

  & .react-calendar__month-view__weekdays {
    justify-content: center;
    text-align: center;
    margin-bottom: 14px;
    font-weight: bold;
    color: $gray-color-text;
    text-transform: capitalize;
  }

  & button {
    background-color: transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
  }
}
