.epow-custom-table.details-table {

  display: flex;
  flex-direction: column;

  .epow-custom-table-header-row.details-table  {
    display: flex;

    .epow-custom-table-header.details-table  {
      display: flex;
      flex: 1 0;
      padding: 0.5rem;

      >span {
        font-weight: bold;
        margin-right: 0.5rem;
      }

      &:last-child {
        >span {
          margin-left: auto;
          margin-right: 1rem;
        }
      }
    }
  }

  &.no-actions {
    .epow-custom-table-header-row.details-table .epow-custom-table-header.details-table:last-child {
      >span {
        margin-left: 0;
      }
    }
  }

  .epow-custom-table-row.details-table  {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    margin-bottom: 0.5rem;

    .epow-custom-table-row-main {
      display: flex;
      flex: 1 0;
      align-items: center;
      border: 1px solid $light;
      border-radius: 5px;
      background-color: $white;

      .col {
        padding: 0.5rem;
      }
    }

    .epow-custom-table-row-details {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      background-color: $gray;

      .epow-custom-table-row-details-date {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        hr {
          flex: 1;
          color: black;
          margin: 0 1rem;
          height: 2px;
        }
      }

      .epow-order-item-preview {
        margin-bottom: 0.25rem;
        border-radius: 5px;
      }



    }
  }

  &.epow-purchase-booking-table {
    .epow-custom-table-row.details-table .epow-custom-table-row-details {
      background-color: $white;
    }
  }

}
