.order-header {
  .order-tour-leader-button {
    background-color: $white;
    border: solid $black 1px;
    border-radius: 15px !important;
    height: 20px;
    color: $black;
    font-size: 14px;
    line-height: 8px;
  }
}

.order_filter_client {
  z-index: 2;
}
