$font-size-header: 12px;

.epow-header-menu {
  background-color: $dark;
  padding: 0 40px;
  height: 62px;
  border: 1px solid $dark;


  @media (max-width: 370px) {
    .epow-logo {
        width: 100px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-left: 250px;
  }

  & .profile-section svg {
    color: $gray-color-text;
  }

  & .input-group {
    max-width: 256px;
    margin-right: 20px;
  }

  & .input-group-text {
    border-radius: 6px 0 0 6px;
    background-color: $dark-gray;
    border: 1px solid $dark-gray;
    color: $gray-color-text;
    height: 32Px;
    padding-right: 0;
    padding-left: 18px;
    font-size: $font-size-header;

  }

  & .epow-input.form-control {
    border: 1px solid $dark-gray;
    border-radius: 0 6px 6px 0;
    background-color: $dark-gray;
    color: $gray-color-text;
    height: 32Px;
    padding: 6px 40px 6px 18px;
    font-size: $font-size-header;

    &::placeholder {
      font-size: $font-size-header;
    }
  }

  & .separator {
    border-left: 1px solid #434E55;
    height: 24px;
    margin: 0 0 0 24px;
  }

  & .btn {
    border-radius: 8px;
    border-width: 2px;
    height: 32Px;
    padding: 4px 16px;
    font-size: $font-size-header;
    font-weight: bold;
  }

  & .badge-rounded {
    height: 32px;
    width: 32px;
    border: 1px solid $dark-gray;
  }

  & .epow-dropdown {
    padding: 15px;
    & .btn {
      width: 100%;
    }

    .dropdown-menu {
      background-color: $dark;
      box-sizing: border-box;
      border: 1px solid #222F3A;
      box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.05), 0px 4px 10px rgba(255, 255, 255, 0.05);
      border-radius: 6px;
      padding: 8px;

      .dropdown-item {
        color: $light;
        padding: 6px 8px;
        border-radius: 6px;
        font-size: 14px;

        &:hover {
          background: rgba(255, 255, 255, 0.15);
          color: $primary;
        }
      }
    }

    .customer_dropdown {
      color: $white;
    }
  }

  .customer-label {
    color: $white;
    font-weight: bold;
    text-align: center;
    flex: 1;
  }
}

.menu-user .btn {
  padding: 0;
  font-size: 10px;

  @include media-breakpoint-down(lg) {
    width: 70%;
    margin: 0;
  }

  & .profile-user-icon {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $dark-gray;

    & svg {
      width: 12px;
      height: 12px;
    }
  }

  & .profile {
    color: $gray-color-text;
    font-size: 8px;
  }

  & .epow-icon {
    color: $gray-color-text;
  }
}
