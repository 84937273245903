.epow-staffing-pending-table {
  .epow-custom-table-header-row.staffing {
    display: flex;

    .epow-custom-table-header.staffing {
      display: flex;
      flex: 1 0;
      padding: 0.5rem;

      >span {
        font-weight: bold;
        margin-right: 0.5rem;
      }

      &:last-child {
        >span {
          margin-left: auto;
          margin-right: 1rem;
        }
      }
    }
  }

  .epow-custom-table-row.staffing {
    margin-bottom: 0.5rem;
    border-radius: 5px;

    .staffing-pending-row {
      display: flex;
      flex: 1 0;
      align-items: center;
      border-radius: 5px;
      background-color: $white;

      .col {
        padding: 0.5rem;
      }
    }

    .epow-staffing-pending-table-row-details {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      background-color: $white;
      border-radius: 5px;
      border-top: 1px solid $light;

      >div {
        align-items: center;
        display: flex;
        flex: 0 1;
        margin-bottom: 0.25rem;

        .icon-gray{
          color: $gray-color-text;
        }

        .icon-green{
          color: $green;
        }
      }

      .btn.pending-staffing-action-button{
        padding-top: 1px;
        padding-bottom: 1px;
      }

    }
  }
}