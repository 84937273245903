.epow-badge.badge {

  white-space: normal;
  font-size: 12px;
  border-radius: 5px;

  @each $name, $value in $badge-theme-colors {
    &.bg-#{$name} {
      background-color: $value !important;
      border: 2px $value solid;
      color: $white;
      &-fill{
        background-color: $value;
        border: 2px $value;
        padding: 6px;
        color: $dark;
      }
    }
  }

  &.badge-rounded {
    border-radius: 50%;
  }

  &-secondary {
    color: $primary;
  }

  &-light-toast {
    color: $primary;
  }

}

.badged-icon {
  position: absolute;
  width: 12px;
  height: 12px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 7px !important;
  right: 0;
  top: 10px;
  border: none !important;
}
