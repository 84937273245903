// IMPORT BOOTSTRAP MIXINS
// --------------------------------------------------
@import "~bootstrap/scss/_mixins.scss";

// DEFAULT MIXINS
// --------------------------------------------------

// @mixin clearfix
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// PROJECT MIXINS
// --------------------------------------------------

/* LINKS */
@mixin link-variant($color, $hover-color: $color) {
  color: $color;

  &:hover, &:focus {
    color: $hover-color !important;
  }
}

// @mixin object-fit
@mixin object-fit($fit: "cover") {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;

  &:after {
    content: "";
    display: block;
    height: 0;
    width: 100%;
  }
  img, .video {
    position: absolute;
    object-fit: $fit;
    width: 100%;
    height: 100%;
  }
}

@mixin ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin ellipsis-lines($lines) {

  @include ellipsis();
  @supports (-webkit-line-clamp: $lines) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
  }
}


@mixin card-color($color) {
  background-color: lighten($color, 47%);
  border: 1px solid $color;
}
