.form-document-card.card {
  border-radius: 10px 10px 0 0;
  .card-header {
    border-radius: 10px 10px 0 0;
    background-color: $gray;
    &.success {
      color: $white;
      background-color: $green;
    }
  }
}

