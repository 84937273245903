.epow-card.card {
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
}

@each $name, $value in $theme-colors {
  .epow-card.card.color-border-#{$name} {
    @include card-color($value);
  }
}

.epow-large-card-kpi.card {
  background: linear-gradient(209.8deg, #675DDA 13.54%, #1F158C 81.79%), #1F158C;
  width: 300px;
  padding: 16px 24px;
}

.epow-card-kpi.card {
  background: linear-gradient(180deg, #293E58 29.93%, #617C9E 100%);
  width: 238px;
  padding: 16px 16px 16px 32px;
  & .value {
    font-size: 24px;
    font-weight: bold;
  }

  & .positive {
     color: $green;
  }
}


.epow-large-card-kpi.card, .epow-card-kpi.card {
  color: $white;
  border: none;
  font-size: 10px;
  height: 142px;

}

.epow-accordion {
  &.primary {
    border-color: $primary-border;
    >.card-header {
      background-color: $primary-light;
    }
  }
  &.secondary {
    border-color: $secondary-border;
    >.card-header {
      background-color: $secondary-light;
    }
  }

  &.white {
    border-color: $white;
    >.card-header {
      background-color: $white;
    }
  }
}

.epow-card-admin-export {
  padding: 1rem;
  border-radius: 10px;
  background-color: $light;
  border: 1px solid $gray;
}
