.epow-pagination {
    position: absolute;

    .epow-pagination-item {
        border: 1px solid $dark-gray;
        background-color: $white;
        color: $dark-gray;
        border-radius: 5px;
        padding: 5px 10px;
        cursor: pointer;
        font-weight: 600;

        &.main-box{
            background-color: $secondary;
            color: $white;
            border-color: $secondary;
        }
    }

    .epow-pagination-spacer {
        padding: 0 1rem;
    }
}
