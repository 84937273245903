.nav-tabs .nav-link.epow-nav-link {
  border-radius: 6px;
  border: 1px solid $white;
  background-color: $white;
  padding: 4px 8px;
  color: $dark;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;

  &.active {
    background-color: $primary;
    color: $white;
  }
}

.nav.nav-tabs {
  border-bottom: none;
}
