.epow-purchase-table {
  display: flex;
  flex-direction: column;


  .epow-purchase-table-header-row {
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 1rem;

    .epow-purchase-table-header {
      display: flex;
      flex: 1 0;

      >span {
        font-weight: bold;
        margin-right: 0.5rem;
      }

      &:last-child {
        >span {
          margin-left: auto;
          margin-right: 1rem;
        }
      }
    }
  }

  .epow-custom-table-row-main  {
    padding: 0.25rem;
  }

  .epow-purchase-table-row-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $white;
    border-radius: 5px;
    padding: 0.5rem;
    margin-bottom: 0.25rem;
  }

}
