.epow-toggle {

  display: flex;

  .epow-toggle-checkbox {
    display: none;
  }

  .epow-toggle-button {
    display: flex;
    padding: 3px;
    cursor: pointer;
    width: 50px;
    height: 25px;
    background-color: $gray;
    border-radius: 40px;
    position: relative;
    transition: background-color .2s;

    .epow-toggle-slider {
      content: '';
      padding: 10px;
      border-radius: 50px;
      transition: 0.2s;
      background: #fff;
    }
  }

  .epow-toggle-checkbox:checked+.epow-toggle-button {
    background-color: $green;

    .epow-toggle-slider {
      transform: translateX(25px);
    }
  }

}