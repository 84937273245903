.epow-label {
  font-size: $font-size-base;
}

form, .epow-filter {
  min-width: 175px;

  &.checkbox {
    min-width: 100px;
  }

  .epow-label {
    font-weight: 600;
    font-size: $font-size-xs;
  }

  .form-label {
    margin-bottom: 0.2rem;
  }
}

.epow-light-label {
  color: $gray-color-text
}

.epow-input {
  font-size: $font-size-base;
  &.epow-input-error.form-control {
    border: 1px solid $danger;
  }
  &.form-control:focus {
    border-color: #2684FF;
    border-width: 2px;
    box-shadow: none;
  }
}

input::placeholder {
  font-size: $font-size-base;
  font-style: italic;
}

.epow-info {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

.epow-edit-input {
  background-color: transparent;
  padding: 8px;
  max-width: 100px;
  max-height: 25px;
  &:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
  &.form-control {
    font-size: $font-size-xs;
  }
}

.epow-edit-input-text-area {
  background-color: transparent;
  padding: 8px;
  &:hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
  &.form-control {
    font-size: $font-size-xs;
  }
}