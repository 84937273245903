
.table_wrapper {
  width: fit-content;
  display: inline-block;
  max-width: 100%;
  min-width: 100%;
  overflow: scroll;
}

.table.staffing-calendar {
  width: fit-content;
  overflow: scroll;
  max-width: 100%;
  min-width: 100%;

  .absolute-resource-circle {
    position: absolute;
    top: 15px;
  }

  &.sticky {
    overflow: scroll;

    .header {
      position: sticky;
      z-index: 4;
      width: fit-content;
    }

    .header {
      z-index: 4;
      top: 0;
    }

    [data-sticky-td] {
      position: sticky;
    }
  }

  & > :not(caption) > * > * {
    padding: 0;
  }

  & > :not(:first-child) {
    border-top: 0;
  }

  .header .tr {
    position: sticky;
    left: 0;
    z-index: 1000;
    direction: ltr;
  }

  .tr {
    padding-bottom: 10px;
  }

  .td {
    overflow: hidden;
  }

  .header .th:first-child {

    background-color: $bg-light;
    z-index: 10;

    &.lg {
      width: 300px;
      height: 78px;
    }

    &.sm {
      width: 100px;
      height: 50px;
    }
  }

  & .staffing-calendar__cell {
    background-color: $white;
    border: 1px solid $gray;
    border-radius: 0;

    &.lg {
      &.header {
        width: 100%;
        min-height: 60px;
        height: 60px;
        padding: 10px;
      }

      min-height: 70px;
      height: 80px;
      padding: 10px;
    }

    &.sm {
      padding: 20px;
      height: 100%;
    }

    & .availability {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    &.epow-bg-green {
      background-color: #d0ffc8;
    }

    &.epow-bg-red {
      background-color: #fcdbde;
    }

    &.epow-bg-blue {
      background-color: $light-blue;
    }

    & .staff-cell-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  .epow-text-green {
    color: $success;
  }

  .epow-text-red {
    color: $danger;
  }

  .epow-text-orange {
    color: $warning-badge;
  }
}


