.epow-table, .epow-order-table, .epow-custom-table, .epow-booking-table, .epow-purchase-table, .epow-staffing-table {
  border-collapse:separate;
  border-spacing: 0 0.5rem;

  :not(.resource-activities-table) {
    &.table :not(caption) * > * {
      border-bottom-width: 0;
    }
  }

  td, th {
    vertical-align: middle;
    input, span {
      vertical-align: middle;
    }
  }

  .epow-table-row {
    border: 1px solid $light;
    background-color: $white;
  }

  .table-action-link {
    color: $secondary;
  }
}

.no-display {
  display: none;
}

.sticky-main-row {
  background-color: rgb(214,228,244) !important;
  position: sticky;
  top:0;
  z-index: 1;
}

@import "./details_table";
@import "./staffing_table";
@import "./staffing_pending_table";
@import "./staffing_confirmed_table";
@import "./booking_table";
@import "./purchase_table";
@import "./activity_billing_table";
@import "./_activity_billing_overview_table";
@import "./documents_table";
