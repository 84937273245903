.epow-login {
  & .epow-error-login {
    color: $danger;
    font-size: $font-size-base;
    font-style: normal;
  }

  .epow-login-rule {
    font-size: $font-size-base;
    text-align: justify;
  }
}