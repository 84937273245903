// IMPORT BOOTSTRAP BASE
// --------------------------------------------------

// TRANSITION
@import "~bootstrap/scss/transitions";

// UTILITIES
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";

// CLOSE
@import "~bootstrap/scss/close";

// PROJECT UTILITIES
// --------------------------------------------------

// Cursor
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

// Responsive
.desktop {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  .border-sm-left {
    border-left: solid;
  }
}

// Text
.text-underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.epow-danger {
  color: $danger;
}

.epow-success {
  color: $success;
}

.epow-secondary {
  color: $secondary;
}

.ellipsis {
  @include ellipsis();
}

*:active, *:focus, *:focus-visible {
  outline: none;
  box-shadow: none !important;
}

$font-sizes: ('10':10px, '12':12px, '14':14px, '16':16px);

@each $name, $value in $theme-colors {
  .color-#{$name} {
    color: $value;
  }
}

@each $name, $value in $theme-colors {
  .bg-theme-#{$name} {
    background-color: $value;
  }
}

@each $name, $value in $font-sizes {
  .fs-#{$name} {
    font-size: $value;
  }
}

$ellipsis-lines: ('1' :1, '2' :2, '3': 3);


@each $name, $lines in $ellipsis-lines {
  .ellipsis-#{$name} {
    @include ellipsis-lines($lines)
  }
}

.wk-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical
}

.flex-basic-100 {
  flex: 1 1 100%;
}


.flex-shrink {
  flex-shrink: 1;
  min-height: 0;
}

.flex-1 {
  flex: 1;
}

.br-sm {
  border-radius: $border-radius-sm;
}
