.page-title {
  font-size: 18px;

  .epow-text-link {
    color: $gray-color-text;
    &:hover {
      color: $secondary;
    }
  }

  .h4 {
    margin: 0;
  }
}