.epow-custom-table.documents-table {

  display: flex;
  flex-direction: column;

  // headers
  .epow-custom-table-header-row.documents-table {
    display: flex;

    .epow-custom-table-header.documents-table {
      display: flex;
      flex: 1 0;
      padding: 0.5rem 0;

      >span {
        font-weight: bold;
        margin-right: 0.5rem;
      }

      &:last-child {
        flex: 0 0 auto;
        >span {
          margin-left: auto;
          margin-right: 1rem;
        }
      }
    }
  }

  &.no-actions {
    .epow-custom-table-header-row.documents-table .epow-custom-table-header.documents-table:last-child {
      >span {
        margin-left: 0;
      }
    }
  }

  // rows
  .epow-custom-table-row.documents-table {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    margin-bottom: 0.5rem;

    // themes
    .epow-custom-table-row-main {
      display: flex;
      flex: 1 0;
      align-items: center;

      .col {
        padding: 0.5rem;
      }

      .theme-line-decorator {
        margin-left: 0.5rem;
        width: 100%;
      }
    }

    // documents
    .document-table-row {
      padding: 0.5rem;
      background-color: $white;

    }
  }

}