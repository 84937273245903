.epow-card-booking-item.card {
  width: 257.78px;
  border: 1px solid #F5F6F7;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 10px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05), 0 0 16px rgba(0, 0, 0, 0.1);
  }

  .booking-info-service {
    font-size: 12px;
  }

  .purchase-icon {
    border: 1px solid $secondary;
    .epow-icon {
      color: $secondary;
    }

    &:hover {
      background-color: $secondary;
      .epow-icon {
        color: $light;
      }
    }
  }

  .booking-info-date-pax {
    max-height: 32px;
  }
}

.booking-item-card-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 81px;
  height: 24px;
  background: $white;
  border-radius: 16px;
}

.to-update {
  border: 1px solid $blue;
  color: $blue
}

.to-cancel {
  border: 1px solid $warning;
  color: $warning
}

.icon-warning {
  width: 24px;
  height: 24px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: none !important;
}