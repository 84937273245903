.epow-lateral-panel {

  @include media-breakpoint-up(sm) {
    min-width: 550px;
    width: 30%;
  }

  height: 100vh;
  position: fixed;
  background-color: white;
  right: 0;
  top: 0;
  z-index: 1000;
  overflow: auto;

  .epow-lateral-panel-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 110px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 20px;
    }
  }

  .epow-lateral-panel-footer {
    background-color: $white;
    position: fixed;
    bottom: 0;
    right: 0;
    height: 76px;
    padding: 20px 20px;

    @include media-breakpoint-up(sm) {
      min-width: 550px;
      width: 30%;
    }
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
