.input-group {
  .input-icon-container {
    background-color: $white;
    color: $gray-color-text;

    .icon-left {
      border-right: none;
    }

    .icon-right {
      border-left: none;
    }
  }

  input::placeholder {
    color: $gray-color-text;
    font-style: normal;
    font-size: 14px;
  }
}