.epow-welcome {
  background-color: $dark;
  color: $light;

  & h1 {
    font-size: 20px;

    & span {
      font-size: 12px;
    }
  }
}


