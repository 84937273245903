.epow-custom-table.activity-billing {
  display: flex;
  flex-direction: column;

  .epow-custom-table-header-row.activity-billing {
    display: flex;

    .epow-custom-table-header.activity-billing {
      display: flex;
      flex: 1 0;
      padding: 0.5rem 0;

      >span {
        font-weight: bold;
        margin-right: 0.5rem;
      }

      &:last-child>span {
        margin-left: auto;
      }
    }
  }

  .epow-custom-table-row.activity-billing {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    margin-bottom: 0.5rem;
  }

}