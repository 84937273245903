.season-item {
  .season-pill a {
    border-radius: 6px;

    &.nav-link {
      color: $black;
      border: 1px solid;
      border-color: $secondary-border;

      &.active {
        background-color: $secondary-light;
      }
    }
  }
}
