.toggle-list {

    .toggle-list-row{
        background-color: $white;
        border: 1px solid $light;
        padding: 0.5rem;
        margin-bottom: 0.5rem;

        >* {
            display: flex;
            align-items: center;
        }
    }

    a.color-secondary {
      color: $secondary;
    }

}
