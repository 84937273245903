.epow-card-booking-header.card {
  width: 257.78px;
  height: 48px;
  border: 1px solid #F5F6F7;
  border-radius: 4px;
}

.booking-header-bar {
  position: absolute;
  width: 3px;
  height: 30px;
  left: 0;
  top: 7px;
}

.booking-header-count {
  width: 30px;
  height: 30px;
}
