.resource-availability-card {
  background-color: $white;
  border-radius: 5px;
  margin: 0.5rem 0;
  padding: 0.5rem;
  width: 80vw;

  @media (min-width: 768px) {
    width: fit-content;
    min-width: 350px;
  }
}

.btn-resource-bulk-unavailable {
  position: sticky;
  bottom: 0;
  padding-bottom: 20px;
  background-color: $bg-light;
}

.resource-availability-filters {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: baseline;

  .epow-filter {
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: end;
  }
}